import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPlusCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Navbar as NavBarComp,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../styles/navbar.css";

const Navbar = ({ currentUser, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavBarComp expand="lg" light container={false} className="bg-light">
      <div className="container-fluid justify-content-end">
        <NavbarBrand className="me-auto" href="/">
          Maphia
        </NavbarBrand>
        <RouterNavLink to="/search" className="nav-link text-dark mx-3">
          <FontAwesomeIcon icon={faSearch} title="Search Maphias" size="lg" />
        </RouterNavLink>
        {currentUser.isLoggedIn && (
          <RouterNavLink to="/maphias/new" className="nav-link text-dark mx-3">
            <FontAwesomeIcon
              icon={faPlusCircle}
              title="Create Maphia"
              size="lg"
              className="maphia-link-icon create-maphia-link"
            />
          </RouterNavLink>
        )}

        <NavbarToggler
          className="ms-3"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {currentUser.isLoggedIn ? (
            <FontAwesomeIcon icon={faUser} />
          ) : (
            <span className="navbar-toggler-icon"></span>
          )}
        </NavbarToggler>

        <Collapse
          id="navbarSupportedContent"
          navbar
          isOpen={isOpen}
          className="flex-grow-0"
        >
          <Nav navbar>
            {currentUser.isLoggedIn ? (
              <>
                <NavItem className="d-flex align-items-center">
                  <NavLink disabled className="text-dark" href="#">
                    {currentUser.name}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" onClick={handleLogout}>
                    Sign Out
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/signin">
                    Sign In
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/signup">
                    Sign Up
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </div>
    </NavBarComp>
  );
};

Navbar.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default React.memo(Navbar);
