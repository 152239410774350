import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "contexts/GlobalAppContext";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import CustomField from "../commonUI/forms/CustomField";
import { singInValidationSchema } from "./validationSchemas";
import { login } from "../../services/usersService";
import { joinMaphia } from "services/maphiaMembers";
import { errorCodes } from "../../constants/errorCodes";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const initialValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const { state } = useLocation();
  const maphia = useMemo(() => {
    if (state?.type === "MAPHIA_TO_JOIN") return state.payload;
  }, [state]);
  const [loading, setLoading] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const handleLoginSuccess = (response) => {
    if (!response?.isSuccessful) {
      throw new Error("Unkown error");
    }
    toast.success("Login Successful", {
      closeOnClick: true,
      autoClose: 500,
      hideProgressBar: true,
    });
    user.getCurrent();
    if (!maphia) navigate("/");
  };

  const handleLoginError = (error) => {
    const errorResponseCode = Number(error?.response?.data?.code);

    if (errorResponseCode === errorCodes.INCORRECT_CREDENTIALS) {
      setInvalidCredentials(true);
    } else {
      toast.error("Error Logging in", error.message);
    }
  };

  const handleJoinMaphia = async () => {
    try {
      await joinMaphia(maphia.id);
      toast.success("Successfully joined Maphia", {
        autoClose: 2000,
      });
      navigate(`/maphia/${maphia.name}`);
    } catch {
      toast.error("Error joining Maphia");
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      const payload = {
        email: values.email,
        password: values.password,
      };

      const response = await login(payload);
      handleLoginSuccess(response);
      if (!!maphia?.id) handleJoinMaphia();
    } catch (error) {
      handleLoginError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!maphia) {
      toast.info(
        "After logging in, your request to join the Maphia will be processed"
      );
    }
  }, [state, navigate]);

  return (
    <div className="d-flex flex-column mx-auto my-2">
      <h3 className="text-center mb-3">Login</h3>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={singInValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <CustomField
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter your email"
              autoComplete="email"
            />
            <CustomField
              name="password"
              type="password"
              className="form-control"
              placeholder="Enter your password"
              autoComplete="new-password"
            />

            <div className="text-center">
              {invalidCredentials && (
                <div className="d-flex align-items-start">
                  <small className="text-danger">Invalid Credentials</small>
                </div>
              )}
              <Button
                type="submit"
                className="bg-gradient-success mt-3 mb-0"
                disabled={
                  loading ||
                  Object.keys(errors).length > 0 ||
                  Object.keys(touched).length === 0
                }
              >
                {" "}
                {loading ? <Spinner size="sm" /> : "Login"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

SignIn.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
};

export default React.memo(SignIn);
