import axios from "axios";
import {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
} from "./serviceHelpers";

const api = `${API_HOST_PREFIX}/users`;

/**
 *
 * @param {{name: string,
 * email: string,
 * password: string,
 * passwordConfirm: string}} payload
 */
export const register = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/register`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * email: string,
 * password: string}} payload
 */
export const login = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/login`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

export const getCurrent = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/current`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

export const logout = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/signout`,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};
