import axios from "axios";
import {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
} from "./serviceHelpers";

const api = `${API_HOST_PREFIX}/users/members`;

/**
 *
 * @param maphiaId {number}
 * */
export const joinMaphia = async (maphiaId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/join`,
    data: { maphiaId },
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};
