import * as Yup from "yup";

export const signUpValidationSchema = Yup.object({
  //firstName and Last name should not have numbers, special characters, or white spaces
  firstName: Yup.string()
    .min(2, ({ min }) => `Name must be at least ${min} characters`)
    .max(100, ({ max }) => `Name must be at most ${max} characters`)
    .matches(
      /^[a-zA-Z]+$/,
      "First Name must contain only letters and no white spaces"
    )
    .required("First Name is required"),
  lastName: Yup.string()
    .min(2, ({ min }) => `Name must be at least ${min} characters`)
    .max(100, ({ max }) => `Name must be at most ${max} characters`)
    .matches(
      /^[a-zA-Z]+$/,
      "Last Name must contain only letters and no white spaces"
    )
    .required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, ({ min }) => `Password must be at least ${min} characters`)
    .max(100, ({ max }) => `Password must be at most ${max} characters`)
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[^A-Za-z\d]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("You must confirm your password"),
});

export const singInValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});
