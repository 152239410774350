import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { Container } from "reactstrap";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAppContext } from "contexts/GlobalAppContext";
import routes from "./routes/routes.js";
import SignIn from "./components/authentication/SignIn.jsx";

const App = () => {
  const { user } = useAppContext();

  const [activeRoutes, setActiveRoutes] = useState(routes.anonymous);

  const mapRoutes = (route, index) => {
    let Component = route.component;
    return (
      <Route
        key={index}
        path={route.path}
        element={<Component />}
        exact={route.exact}
      />
    );
  };

  useEffect(() => {
    if (user.current.isLoggedIn) {
      setActiveRoutes(routes.user);
    } else {
      setActiveRoutes(routes.anonymous);
    }
  }, [user.current.isLoggedIn]);

  return (
    <div>
      <Navbar currentUser={user.current} handleLogout={user.logout} />
      <Container>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            {activeRoutes.map(mapRoutes)}
          </Routes>
        </Suspense>
      </Container>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default App;
