import React from "react";
import { Field } from "formik";
import { FormGroup, Label } from "reactstrap";
import CustomErrorMessage from "./CustomErrorMessage";
import PropTypes from "prop-types";

const CustomField = ({ hasValidationIcons = true, ...props }) => {
  return (
    <FormGroup floating>
      <Field
        {...props}
        className={`form-control ${props.className}`}
        placeholder={props.placeholder}
      />
      <Label for={props.name} className="text-body-secondary">
        {props.placeholder}
      </Label>
      {hasValidationIcons && <CustomErrorMessage name={props.name} />}
    </FormGroup>
  );
};

export default CustomField;

CustomField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  hasValidationIcons: PropTypes.bool,
};
