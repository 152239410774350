import { lazy } from "react";

const anonymousRoutes = [
  {
    path: "/",
    component: lazy(() => import("../components/Home")),
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("../components/PrivacyPolicy")),
    exact: true,
  },

  {
    path: "/signup",
    component: lazy(() => import("../components/authentication/SignUp")),
    exact: true,
  },
  {
    path: "/maphia/:name",
    component: lazy(() => import("../components/maphias/MaphiaProfile")),
    exact: true,
  },
  {
    path: "/search",
    component: lazy(() =>
      import("../components/maphias/searchView/SearchView")
    ),
    exact: true,
  },
];

const userRoutes = [
  {
    path: "/maphias/new",
    component: lazy(() => import("../components/maphias/AddEditMaphia")),
    exact: true,
  },
];

const routes = {
  anonymous: anonymousRoutes,
  user: [...anonymousRoutes, ...userRoutes],
};

export default routes;
