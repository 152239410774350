import React from "react";

const Footer = () => {
  return (
    <footer className="text-center mx-auto">
      <p>
        &copy; 2024 Maphia. All rights reserved.{" "}
        <a href="/privacy-policy" className="footer-link">
          Privacy Policy
        </a>
      </p>
    </footer>
  );
};

export default React.memo(Footer);
